:root {
  --popup-close-diameter: 35px;
}

@media screen and (max-width: 767px) {
  .popup, .popup-card, .popup-close-container, .popup-close {
    display: none!important;
  }
}

.popup {
  right: 60px;
  bottom: 20px;
  position: fixed;
  z-index: 1000;
  animation: 0.75s slideIn;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  animation-timing-function: ease-out;
}

.popup-card {
  width: 300px;
  position: relative;
  height: fit-content;
  margin: 40px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 2px #ccc;
}
.popup-card img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.popup-close-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.popup-close {
  height: var(--popup-close-diameter);
  width: var(--popup-close-diameter);
  border-radius: var(--popup-close-diameter);
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px 2px #666;
}

.popup-close:hover svg {
  transform: rotate(90deg);
  transition: 0.5s;
}

.popup-close p {
  margin-bottom: 5px;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100px);
  }
  50% {
    transform: translateY(-550px);
  }
  100% {
    transform: translateY(-500px);
  }
}
