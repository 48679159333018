.popup-supernova {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.popup-supernova h6 {
  max-width: 280px;
  margin-bottom: 0.125rem;
}

.popup-supernova img {
  filter: drop-shadow(2px 2px 2px #555);
  object-fit: scale-down;
  opacity: 1.0;
}

.popup-supernova:hover {
  cursor: pointer;
}
