.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
}

.pagination > li {
    display: inline;
}

.card {
    min-width: 200px;
    max-width: 260px;
    margin: 10px;
    display: flex;
    align-items: center;
    border: none;
}

.card-container {
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.filter-container {
    width: 300px;
    height: 400px;
    margin: 10px;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

#root {
    display: flex;
    justify-content: center;
}

.main-container {
    display: flex;
}

@media screen and (max-width: 767px) {
  .main-container {
    align-items: center;
  }

  .products-title {
    text-align: center;
  }
}

.card-conatiner img {
    margin: 40px;
    object-fit: scale-down;
    clip-path: inset(5px);
}

.card-popover {
    max-height: 110%;
    width: 110%;
    height: 110%;
    position: absolute;
    box-shadow: 0 0 30px #AAA;
    opacity: 0;
}

.card-popover:hover {
    display: block!important;
    opacity: 1;
    z-index: 100;
    cursor: pointer;
}

.card h6 {
    text-align: center;
}

.product-root {
    display: flex;
}

.swatch {
    margin: 2px;
}

.product-root h1 {
    padding-top: 60px;
}

h1 {
    font-weight: thin;
}

@media screen and (max-width: 767px) {
    .product-root {
        flex-direction: column;
        align-items: center;
    }

    .main-container {
      flex-direction: column;
    }

    .filter-container {
      height: auto;
    }
}
