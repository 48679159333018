.main-container {
  min-height: 700px;
}

.tooltip {
  opacity: 1.0!important;
}

.tooltip-inner, .tooltip-arrow {
  background: #222;
}

.spec-sheet {
    max-width: 1000px;
    margin: auto;
    width: 100%;
}

.spec-sheet table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
}

.spec-sheet tr:nth-child(odd) {
    background-color: #fbfbfb;
}

.spec-sheet td:first-child {
    font-weight: bold;
}

.spec-sheet table > tbody > tr > td, table > tfoot > tr > td {
    padding: 11px 10px;
}

.product-root h3 {
    max-width: 450px;
}

@media screen and (max-width: 1100px) {
  .spec-sheet {
      max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .main-image {
    max-width: 300px;
  }

  .spec-sheet {
    width: 100%!important;
  }

  .product-root h3, .product-root .sku {
    text-align: center;
  }
}

@media print {
  .request-btn {
    display: none!important;
  }
}
