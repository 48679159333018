.spinning-3dchair-container {
  background-color: white;
  width: 90%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 40px;
  padding: 40px;
  position: relative;
  min-height: 800px;
  user-select: none;
  flex-direction: column;
}

.spinning-3dchair-container > img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media screen and (max-width: 1000px) {
  .spinning-3dchair-container {
    min-height: 500px;
  }
}
