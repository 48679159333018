.spec-sheet {
  font-family: arial;
}

.spec-sheet .btn {
  margin: 10px;
}

@media print {
  .spec-sheet .btn {
    visibility: hidden;
  }
}
