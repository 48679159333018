.swatch-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    max-width: 330px;
}

@media print {
  .swatch-group {
    display: none!important;
  }

  .swatch-group-label {
    display: none!important;
  }
}
