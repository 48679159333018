.iFrame {
  border: none;
  margin: 20px auto 40px auto;
}

@media screen and (orientation: portrait) and (max-width: 834px) {
  .iFrame {
    border: none;
    width: 100vw;
    height: 100vh;
    margin: 0;
    box-shadow: none;
  }
}

@media screen and (orientation: landscape) and (max-width: 1112px) {
  .iFrame {
    border: none;
    width: 100vw;
    height: 100vh;
    margin: 0;
    box-shadow: none;
  }
}

.main {
  max-width: 1200px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.build-a-chair-container {
  display: flex;
  max-width: 1200px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .build-a-chair-container {
    flex-direction: column;
    align-items: center;
  }
}
