body {
  overflow-x: hidden;
  font-family: "Roboto";
}

.products-container {
  width: 100vw!important;
}

.topbar {
  text-align: center;
  width: 100%;
  background-color: #1F1F1F;
  color: white;
  font-weight: 900;
  padding: 20px;
}

.topbar > h1 {
  font-size: 40px;
  font-weight: bold;
  margin: 20px;
}

.topbar > h3 {
  font-size: 24px;
  font-weight: bold;
  margin: 20px;
}

.lab-img {
  width: 100%;
  object-fit: contain;
}

.filter-bound-top {
  margin: 0px auto;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: space-between;
}

.tools {
  position: absolute;
  right: 100px;
  top: 100px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*background-color: rgba(0, 0, 0, 0.75);*/
  background-color: rgba(255, 255, 255, 0.275);
  text-align: center;
  color: black;
  padding: 40px;
  z-index: 11;
  backdrop-filter: blur(5px);
  border-radius: 10px;
}

.tools > h1 {
  max-width: 450px;
  font-size: 2em;
  font-weight: bold;
  margin: 20px;
}

.tools > h3 {
  font-size: 1.5em;
}

.tools-img {
  width: 180px;
  object-fit: scale-down;
  filter: invert(1);
}

.build-btn {
  background-color: #0076BA;
  border: none;
  color: white;
  font-weight: bold;
  width: 100%;
  padding: 10px;
  margin: 30px;
}

.build-btn:hover {
  text-decoration: none;
  color: lightblue;
}

.chair-360 {
  background-color: #1F1F1F;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chair-360 > h3 {
  margin: 60px;
  max-width: 1200px;
  font-weight: 500;
  text-align: center;
}

.chair-360 > img {
  width: 1000px;
  object-fit: scale-down;
  background-color: #111;
  margin-top: 10px;
  margin-bottom: 80px;
  border-radius: 10px;
}

.lab-and-healthcare {
  background-color: #1F1F1F;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.lab-and-healthcare > img {
  width: 35%;
  max-width: 500px;
}

.lab-and-healthcare > div {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 900px; 
}

.lab-and-healthcare > div > h2 {
  color: #0F81C1;
  font-weight: bold;
  text-align: center;
  margin: 40px;
}

.lab-and-healthcare p {
  color: white;
  font-size: 1.5em;
  text-align: center;
}

.shop-now-btn {
  padding: 20px 200px;
  white-space: nowrap;
  margin: 40px;
  background-color: #6B7276;
  color: white;
}

.controlled-environment {
  background-color: #F1F1F1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.controlled-environment-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.controlled-environment > .img-container {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  height: 90%;
}

.controlled-environment-inner > .img-container > div {
  padding: 40px 0px;
  background-color: #DADADA;
  border-radius: 15px;
}

.controlled-environment-inner > div > div > img {
  max-width: 500px;
}

.controlled-environment-inner > div {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 700px; 
}

.controlled-environment > h2 {
  color: #1F1F1F;
  font-weight: bold;
  text-align: center;
  margin: 40px;
  margin-bottom: 0px;
}

.controlled-environment p {
  color: #1F1F1F;
  font-size: 1.4em;
  text-align: center;
}

.office-environment {
  background-color: #F1F1F1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.office-environment > .img-container {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  height: 90%;
}

.office-environment > .img-container > div {
  padding: 40px 0px;
  background-color: #DADADA;
  border-radius: 15px;
}

.office-environment > div > div > img {
  max-width: 500px;
}

.office-environment > div {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 900px; 
}

.office-environment > div > h2 {
  color: #1F1F1F;
  font-weight: bold;
  text-align: center;
  margin: 40px;
}

.office-environment p {
  color: #1F1F1F;
  font-size: 1.5em;
  text-align: center;
}

#root > div > div > div.products-container > div.office-environment > div.img-container > div > img {
  max-width: 400px;
}

.shop-now-btn--dark {
  padding: 20px 200px;
  white-space: nowrap;
  margin: 40px;
  background-color: #0F81C1;
  color: white;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 900px;
}

.overlay-filter {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  background-color: blue;
  opacity: 0.125;
}

@media screen and (min-width: 992px) {
  .home-navigation, .navigation {
    padding-left: 60px!important;
    padding-right: 60px!important;
  }
}

@media screen and (max-width: 990px) {
  .tools {
    width: 100%;
    right: 0;
    top: 0px;
    max-width: unset;
    position: relative;
  }

  .chair-360 > img {
  }
}

@media screen and (max-width: 1550px) {
  .controlled-environment-inner > div > div > img {
    max-width: 400px;
  }
  
  .office-environment > div > img {
    max-width: 300px!important;
  }

  .shop-now-btn, .shop-now-btn--dark {
    padding: 20px 150px;
  }
}

@media screen and (max-width: 1250px) {
  .controlled-environment-inner > div > div > img {
    max-width: 300px;
  }

  .office-environment > div > div > img {
    max-width: 300px!important;
  }
}

@media screen and (max-width: 1000px) {
  .chair-360 > img {
    width: 100%;
  }

  .lab-and-healthcare {
    width: 100vw;
    flex-direction: column;
  }
  .lab-and-healthcare > div {
    width: 90%;
    min-height: 0px;
  }

  .lab-and-healthcare > img {
    width: 50%;
    margin-top: 50px;
  }

  .controlled-environment-inner {
    flex-direction: column-reverse;
  }
  .controlled-environment-inner > div {
    width: 90%;
    min-height: 0px;
  }
  .controlled-environment-inner  > img {
    max-width: 200px;
  }

  .office-environment {
    flex-direction: column-reverse;
  }
  .office-environment > div {
    width: 90%;
    min-height: 0px;
  }
  .office-environment > div > div > img {
    max-width: 200px!important;
  }

  .shop-now-btn, .shop-now-btn--dark {
    padding: 20px 100px;
  }

  .controlled-environment > .img-container > div {
    padding: 40px 100px;
  }

  .office-environment > .img-container > div {
    padding: 40px 100px;
  }
}

